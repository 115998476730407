<template>
  <div class="rc-container">
    <KeyValue 
      :left="3" :right="3"
      title="email" 
      placeholder="Help Admin Email" 
      :disabled="isSaving" 
      v-on:value="setValue" />
    
    <SaveRow 
      :left="3" :right="3" 
      :disabled="isSaving" 
      v-on:value="save" />
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import KeyValue from '@/components/input/KeyValue.vue';
import SaveRow  from '@/components/row/SaveRow.vue'

export default {
  name: 'register',
  components: {
    KeyValue,
    SaveRow,
  },
  data() {
    return {
      isSaving: false,
      kvps: {},
    };
  },
  watch: {
    auth_connected() {
      if (this.auth_connected) {
        this.send()
      }
    },
  },
  computed: {
    ...mapGetters([ 'auth_socket', 
                    'auth_connected', 
                    'auth_socket_status', 
                    'auth_socket_message', 
                    'auth_client',
                    'register_event',
                    'domain'
                    ]),
  },
  mounted: function () {
    if (this.domain) {
      this.domain.session().companyName = null;
    }

    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN })
    }
  },
  methods: {
    setValue: function (kvp) {
      ContentUtils.set(this.kvps, kvp, this.domain.logger());
    },
    save: function () {
      this.isSaving = true;
      var event = this.domain.events().adminUserSignUp(this.kvps);
      event.send(this.listener);
    },
    listener: function(event) {
      if (event) {
        //
      }
      this.isSaving = false;
    }
  },
}
</script>
